<template>
  <page icon="plus-circle" :title="`Open New ${Naming.Job}`">
    <div slot="tools">
      <route class="button is-cool-blue is-rounded" name="job-index">
        <icon icon="angle-left"/>
        <span>All {{ Naming.Jobs }}</span>
      </route>
    </div>

    <form class="box p-2" enctype="multipart/form-data">

      <div class="grid has-2-columns gap-2">
        <!-- Left -->
        <div class="grid has-2-columns gap-1">

          <!-- Job Type -->
          <server-data-selector
              required
              searchable
              :prompt-label="`Search for a ${Naming.JobType}…`"
              :items="jobTypeList"
              :error="$root.errors.job_type_id"
              :value="job.job_type_id"
              @input="jobType"
              value-key="id"
              label-key="description"
              :on-type="searchJobTypes"
              :on-lose-focus="clearJobTypeFilter">
            {{ Naming.JobType }}
          </server-data-selector>

          <!-- Reference -->
          <text-input
              :error="validateInput(job.reference, 255, 'Reference')"
              :value="job.reference"
              :hint="`Such as an internal ${Naming.Company} reference`"
              @input="reference">
            Reference (optional)
          </text-input>

          <!-- Technician -->
          <server-data-selector
              required
              searchable
              class="is-2-columns"
              :error="$root.errors.technician_id"
              :items="userList"
              :value="job.technician_id"
              @input="selectTechnician"
              :prompt-label="`Assign a ${Naming.Technician}`"
              label-key="full_name"
              value-key="id"
              :on-type="searchTechnician"
              :on-lose-focus="clearTechnicianFilter">
            {{ Naming.Technician }}
            <div slot="item" slot-scope="{ item }" class="is-flex align-center">
              <avatar
                  :size="28"
                  :path="item.picture_url"
                  :name="item.full_name"/>
              <div class="is-flex flex-column ml-1">
                <span class="has-text-weight-bold">{{ item.full_name }}</span>
                <span class="has-text-weight-light is-size-7">
                  {{ item.email }}
                  <span class="has-tooltip-right" data-tooltip="Admin">
                    <icon v-if="isAdmin(item.roles)" icon="user-shield"/>
                  </span>
                </span>
              </div>
            </div>
          </server-data-selector>

          <!-- Start Date -->

          <!-- Flatpickr Bug: minDate appeats to not work on mobile.
                Falling back to no mobile-contraint for now.
                [mike 21 Jan 2020] -->
          <div class="columns">
            <div class="column">
              <date-picker
                  class="is-hidden-mobile"
                  required
                  @input="startDate"
                  :min-date="(new Date)"
                  :value="job.start_date"
                  :error="$root.errors.start_date">
                Start Date
              </date-picker>
              <date-picker
                  class="is-hidden-tablet"
                  required
                  @input="startDate"
                  :value="job.start_date"
                  :error="$root.errors.start_date">
                Start Date
              </date-picker>
            </div>
            <div class="column">
              <date-picker
                required
                time
                no-calendar
                @input="startTime"
                :value="job.start_time"
                :error="$root.errors.start_time">
                Start Time
              </date-picker>
            </div>
          </div>

          <!-- End Date -->
          <div class="columns">
            <div class="column">
              <date-picker
                class="is-hidden-mobile"
                required
                @input="endDate"
                :min-date="(new Date)"
                :value="job.end_date"
                :error="$root.errors.end_date">
                End Date
              </date-picker>
              <date-picker
                  class="is-hidden-tablet"
                  required
                  @input="endDate"
                  :value="job.end_date"
                  :error="$root.errors.end_date">
                End Date
              </date-picker>
            </div>
            <div class="column">
              <date-picker
                  required
                  time
                  no-calendar
                  @input="endTime"
                  :value="job.end_time"
                  :error="$root.errors.end_time">
                End Time
              </date-picker>
            </div>
          </div>

          <checkbox-input
              :description="`If checked the ${Naming.Job.toLowerCase()} will require the customer to provide a signature on the ${Naming.Job.toLowerCase()} before closing it off.`"
              :value="job.requires_signature"
              classes="is-large"
              @input="toggleSignatureRequirement">Require a Signature
          </checkbox-input>

          <!-- Special Instructions -->
          <text-area
              rows="5"
              class="is-2-columns"
              :description="`If provided, these will be included in the notification email sent to the assigned ${Naming.Technician.toLowerCase()}.`"
              :value="job.special_instructions"
              @input="specialInstructions">
            Special Instructions (optional)
          </text-area>

        </div>

        <!-- Right -->
        <article>
          <div class="grid gap-1">
            <!-- Site -->
            <server-data-selector
                required
                searchable
                :value="job.site_id"
                :error="$root.errors.site_id"
                @input="updateSite"
                :items="siteList"
                :prompt-label="`Select a ${Naming.Site}…`"
                label-key="name"
                value-key="id"
                :on-type="searchSite"
                :on-lose-focus="clearSiteFilter">
              {{ Naming.Site }}
              <div slot="item" slot-scope="{ item }" class="is-flex align-center">
                <avatar
                    :size="28"
                    :path="item.logo_url"
                    :name="item.name"/>
                <div class="is-flex flex-column ml-1">
                  <span class="has-text-weight-bold">{{ item.name }}</span>
                </div>
              </div>
            </server-data-selector>
            <p class="help is-danger" v-if="job.technician_id && job.site_id && !siteIsAssignedToTechnician">
              This {{ Naming.Site.toLowerCase() }} is not part of the {{ Naming.Technicians.toLowerCase() }} {{ Naming.Site.toLowerCase() }} list and will be assigned to the {{ Naming.Technicians.toLowerCase() }} once this {{ Naming.Job.toLowerCase() }}
              is opened.
            </p>

            <!-- Zones -->
            <div class="is-flex align-center">
              <field-label class="flex-grow is-marginless">{{ Naming.Zones }}</field-label>
              <div class="buttons has-addons" v-if="zoneList.length">
                <action-button
                    @click="selectAllZones"
                    :disabled="job.zones.length === zoneList.length"
                    class="is-small is-cool-blue-dark is-rounded">
                  Select All
                </action-button>
                <action-button
                    :disabled="job.zones.length === 0"
                    @click="deselectAllZones"
                    class="is-small is-cool-blue-dark is-rounded">
                  Deselect All
                </action-button>
              </div>
            </div>
            <div class="scroll-view" style="height: 21.5rem">
              <checkbox-group
                  v-if="zoneList.length"
                  required
                  :label="false"
                  :error="$root.errors.zones"
                  :value="job.zones"
                  @input="updateZones"
                  :items="zoneList"
                  classes="is-circle"
                  label-key="name"
                  value-key="id"/>
              <p class="has-text-centered" v-else>
                Please select a {{ Naming.Site.toLowerCase() }} to show its {{ Naming.Zones.toLowerCase() }}.
              </p>
            </div>

            <!-- Documents -->
            <div>
              <field-label class="flex-grow is-marginless">Documents (.pdf / .xlsx)</field-label>
              <div class="mt-1" v-if="job.documents.length">
                <div class="is-flex align-items-center pl-1 pb-1" v-for="(document, key) in job.documents" :key="document.id">
                  <button @click.prevent="job.documents.splice(key, 1)" class="button"><i class="fa fa-trash"></i>
                  </button>
                  <div class="pl-1">
                    <span class="pr-1">({{ (document.size / 1000 / 1000).toFixed(3) }} MB)</span>
                    {{ document.name }}
                  </div>
                </div>
              </div>
              <p
                  class="has-text-danger"
                  v-if="isSumOfDocumentsSizeValid === false || typeof $root.errors[`documents`] !== 'undefined'"
              >The summed size of all documents cannot exceed 5MB.</p>
              <input
                  type="file"
                  class="mt-1"
                  @change="onDocumentSelected"
                  accept="image/png,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  multiple
              >
            </div>
          </div>
        </article>
      </div>

      <div class="buttons mt-2">
        <submit-button
            :disabled="$root.hasAbility('create-jobs', 'Billow\\Models\\Job') === false || isSumOfDocumentsSizeValid === false || job.reference.length>255"
            class="is-success is-rounded"
            :working="working"
            @submit="store"
            left-icon="check-circle">
          Open {{ Naming.Job }}
        </submit-button>
      </div>
    </form>
  </page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { find, forEach } from 'lodash'
import Search from '@/utils/search'
import textLengthValidation from '@/utils/validationUtils'

export default {

  data: () => ({
    working: false,
    activeSite: null,
    loaded: false,
    selectedTechnician: {
      id:  null,
      sites: []
    }
  }),

  async beforeMount() {
    this.$store.commit('job/clearJob')
    if (this.$route.query.site) {
      this.updateSite(Number(this.$route.query.site))
    }
    if (this.$route.query.start_date) {
      this.startDate(this.$route.query.start_date)
    }
    this.loaded = true
  },

  created() {
    this.clearAllStores()
    Search.searchByUri('', 'jobType/searchActiveJobTypes', this.$store, 0),
    Search.searchByUri('', 'site/searchSite', this.$store, 0),
    Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('jobType', [
      'setJobTypeList',
    ]),
    ...mapMutations('job', [
      'reference',
      'technician',
      'updateZones',
      'clear',
      'startDate',
      'startTime',
      'endDate',
      'endTime',
      'allowEarlyClosure',
      'specialInstructions',
      'toggleSignatureRequirement'
    ]),
    jobType(jobTypeId) {
      this.$store.commit('job/jobType', jobTypeId)

      let jobType = find(this.jobTypeList, type => type.id === jobTypeId)

      if (jobType.requires_signature) {
        this.$store.commit('job/jobTypeRequiresSignature')
      }
    },
    selectTechnician(technicianId) {
      if(typeof technicianId !== "number") return
      this.selectedTechnician.id = technicianId
      this.$store.commit('job/technician', technicianId)
      this.$store.dispatch('user/loadUserSiteIds', technicianId)
          .then((siteIds) => {
            this.selectedTechnician.sites = siteIds
          })
          .catch((error) => {
            this.$whoops()
          })
      this.selectedTechnician = find(this.userList, user => user.id === technicianId)
    },
    updateSite(siteId) {
      this.$store.commit('job/site', siteId)
      this.activeSite = find(this.siteList, site => Number(site.id) === Number(siteId))
    },
    selectAllZones() {
      this.updateZones(this.activeSite.zones.map(zone => zone.id))
    },
    deselectAllZones() {
      this.updateZones([])
    },
    store() {
      this.working = true
      this.$store.dispatch('job/store')
          .then(job => {
            this.$toast.success(this.Convert(this.$lang.job.opened))
            this.$router.push({
              name: 'job-handler',
              params: {
                site: job.site_id,
                job: job.id
              }
            });
          })
          .catch(error => {
            this.$whoops()
          })
          .finally(() => {
            this.working = false
          });
    },
    onDocumentSelected(event) {
      let files = event.target.files
      if (!files.length) return

      forEach(files, file => {
        this.job.documents.push(file)
      })

      event.target.value = ''
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchJobTypes(text) {
      Search.debouncedSearchByUri(text, 'jobType/searchActiveJobTypes', this.$store)
    },
    clearTechnicianFilter() {
      this.setUserList([])
    },
    clearJobTypeFilter() {
      this.setJobTypeList([])
    },
    clearSiteFilter() {
      this.setSiteList([])
    },
    clearAllStores() {
      this.setUserList([])
      this.setSiteList([])
      this.setJobTypeList([])
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('jobType', [
      'jobTypeList'
    ]),
    ...mapGetters('user', [
      'userList'
    ]),
    ...mapGetters('job', [
      'job'
    ]),
    zoneList() {
      return (this.activeSite) ? this.activeSite.zones : []
    },
    isAdmin() {
      return roles => find(roles, role => role.name === 'admin')
    },
    siteIsAssignedToTechnician() {
      if (!this.job.site_id || !this.job.technician_id) return true

      return this.selectedTechnician.sites.includes(this.job.site_id);
    },
    isSumOfDocumentsSizeValid() {
      let sumSize = 0
      forEach(this.job.documents, document => sumSize += document.size)
      sumSize = sumSize / 1000 / 1000

      return sumSize <= 5;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearAllStores()
    next()
  }
}
</script>
