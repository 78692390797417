<template>
  <page v-bind="{ loading }" icon="calendar-alt" :title="calendarTitle" class="is-fullpage">
    <template #tools>
      <div class="is-flex align-center">
        <div class="buttons has-addons is-marginless pr-1">
          <action-button
            @click="prevMonth"
            class="is-rounded is-marginless is-cool-blue-dark has-tooltip-bottom"
            data-tooltip="Previous Month">
            <icon icon="chevron-left"/>
          </action-button>
          <action-button
            @click="today"
            class="is-rounded is-marginless is-cool-blue">
            <span class="is-hidden-mobile">Today</span>
            <icon class="is-hidden-tablet"  icon="crosshairs"/>
            <span class="is-hidden-mobile"/>
          </action-button>
          <action-button
            @click="nextMonth"
            class="is-rounded is-marginless is-cool-blue-dark has-tooltip-bottom"
            data-tooltip="Next Month">
            <icon icon="chevron-right"/>
          </action-button>
        </div>
        <route exact
          name="job-schedule"
          class="button is-rounded is-cool-blue-dark mr-1">
          <span class="is-hidden-mobile"/>
          <icon icon="calendar-week"/>
          <span class="is-hidden-mobile">Schedule</span>
        </route>
        <new-job-dropdown />
      </div>
    </template>
    <template #default>
      <section class="calendar-container">
        <div class="calendar-wrapper">
          <full-calendar
            ref="calendar"
            v-bind="calendarOptions"
            :events="jobCalendar"
            :plugins="calendarPlugins"
            :eventRender="renderEvent"
            @dateClick="newJob"
            @eventClick="handleJobClick"
          />
        </div>
      </section>
    </template>
  </page>
</template>

<script>
import { mapState } from 'vuex'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import { jobCalendarPopover, scheduledJobCalendarPopover } from '@/modals'

export default {

  components: {
    FullCalendar
  },

  data: () => ({
    loading: true,
    calendarApi: null,
    calendarTitle: '',
    calendarOptions: {
      defaultView: 'dayGridMonth',
      weekNumbers: true,
      weekNumberCalculation: 'ISO',
      weekNumbersWithinDays: true,
      height: 'parent',
      header: false,
      eventLimit: true,
    },
    calendarPlugins: [
      dayGridPlugin,
      interactionPlugin
    ],
  }),

  async mounted() {
    await this.$store.dispatch('job/loadJobCalendar')
    this.loading = false
    this.$nextTick(() => {
      this.calendarApi = this.$refs.calendar.getApi()
      this.calendarTitle = this.calendarApi.view.title
    })
  },

  methods: {
    renderEvent({ event, el }) {
      this.renderColorOnto(el, event.extendedProps.current_status.value)
    },

    renderColorOnto(element, status) {
      let statusClass = {
        open: 'is-info',
        failed: 'is-danger',
        passed: 'is-success',
        'in progress': 'is-link',
        pending: 'is-light',
      }[status]
      element.classList.add('calendar-event')
      element.classList.add(statusClass)
    },

    newJob(context) {
      let date = moment(context.dateStr)
      if (date.diff(moment().startOf('day'), 'days') >= 0) {
        this.$router.push({
          name: 'job-create',
          query: {
            start_date: context.dateStr
          }
        })
      }
    },

    handleJobClick({ event }) {
      (event.extendedProps.type === 'job')
        ? jobCalendarPopover(event)
        : scheduledJobCalendarPopover(event)
    },

    prevMonth() {
      this.calendarApi.prev()
      this.calendarTitle = this.calendarApi.view.title
    },

    nextMonth() {
      this.calendarApi.next()
      this.calendarTitle = this.calendarApi.view.title
    },

    today() {
      this.calendarApi.today()
      this.calendarTitle = this.calendarApi.view.title
    },
  },

  computed: {
    ...mapState('job', [
      'jobCalendar'
    ]),
  }

}
</script>
