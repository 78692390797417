<template>
  <page v-bind="{ loading }" icon="hashtag" :title="Naming.AllJobs" :loading-text="`Loading ${Naming.Jobs}…`">

    <!-- Index Tools -->
    <template #tools>
      <dropdown-wrapper position="bottom-end" v-if="jobs.data.length">
        <action-button
          id="dropdown-trigger"
          slot="trigger"
          class="is-primary is-inverted has-text-weight-bold mr is-rounded"
          left-icon="download">
          Download Report
        </action-button>
        <dropdown-item
          class="mb-1 is-flex justify-center has-text-white has-background-dark is-size-7"
          v-if="jobs.meta && jobs.meta.total > 1000">
          Exports are limited to export 1000 records at a time.
        </dropdown-item>
        <dropdown-item :working="pullingReport" @click="pullReport('pdf')">
          <div>
            <b v-show="jobs.meta.total > 1000">Email</b>
            Report (PDF)
          </div>
        </dropdown-item>
        <dropdown-item :working="pullingReport" @click="pullReport('xlsx')">
          <div>
            <b v-show="jobs.meta.total > 1000">Email</b>
            Report (XLSX)
          </div>
        </dropdown-item>
      </dropdown-wrapper>
      <new-job-dropdown v-if="hasAbility('Billow\\Models\\Job', 'create-jobs')"/>
    </template>

    <template #default>
      <template>
        <!-- Filters -->
        <div class="box">
          <job-filters @updated="appliedFilters = $event" :with-client="isAdmin"/>
        </div>

        <pager v-if="jobs.meta.to > 1"
          jump-controls
          class="mb-1"
          button-class="is-cool-blue-darker is-rounded"
          details-class="is-rounded"
          :pageable="jobs"
          @nav="goToPage"
          :context="Naming.Jobs.toLowerCase()"/>

        <!-- Job (Cards) -->
        <div class="boxes is-dense mb-1">
          <div v-for="job in jobs.data" :key="job.id"
            class="box is-hoverable is-hover-parent is-paddingless">

            <!-- Main -->
            <div class="grid gap-1 is-main has-2-columns p-1 pl-2 align-center cursor-pointer"
              @click="navigateToJob(job.id)">
              <article class="min-width-0 border-right">
                <div class="is-flex align-center pr-1">
                  <span class="tag is-hover-child mr-1 is-medium is-cool-blue has-text-weight-light">#{{
                      job.number
                    }}</span>
                  <avatar class="flex-shrink-none mr-1" :size="35" :path="job.site ? job.site.logo_url : null"
                    :name="job.site.name"/>
                  <div class="has-text-cool-blue-text-dark is-flex flex-column min-width-0">
                    <p class="has-text-cool-blue-text-dark whitespace-no-wrap truncated is-flex-mobile flex-column">
                      <span class="has-text-black-ter">{{ job.type.description }}</span>
                      <span v-if="job.reference"><span class="is-hidden-mobile"> — </span>{{ job.reference }}</span>
                    </p>
                    <route name="site-overview" :params="{ site: job.site.id }"
                      class="has-text-weight-light whitespace-no-wrap truncated">{{ job.site.name }}
                    </route>
                  </div>
                </div>
              </article>
              <section class="grid is-mobile gap-1 has-3-columns">
                <article class="is-flex align-center">
                  <div class="is-flex flex-column">
                    <span class="has-text-cool-blue-text-dark is-size-7">assigned to</span>
                    <route v-if="isAdmin"
                      name="user-overview" :params="{ user: job.technician.id }"
                      :data-tooltip="'Manage ' + Naming.User"
                      class="has-text-weight-light has-text-info has-tooltip-right has-tooltip-primary pr">
                      {{ job.technician.full_name }}
                    </route>
                    <span v-else class="has-text-weight-light has-text-info pr">
                      {{ job.technician.full_name }}
                    </span>
                  </div>
                </article>
                <article class="is-flex flex-column">
                  <span class="has-text-cool-blue-text-dark is-size-7">{{
                      job.start_date === job.end_date ? 'start date' : 'period'
                    }}</span>
                  <span class="has-text-weight-light">
                    {{ job.start_date | date('L') }}
                    <template v-if="job.end_date && job.start_date !== job.end_date"> – 
                      {{ job.end_date | date('L') }}
                    </template>
                  </span>
                </article>
                <article class="is-flex flex-column">
                  <span class="has-text-cool-blue-text-dark is-size-7">completion</span>
                  <span v-if="job.completed_at"
                    class="has-text-weight-light">{{ job.completed_at | date('L LTS') }}</span>
                  <span v-else class="has-text-weight-light">Not completed</span>
                </article>
              </section>
            </div>

            <!-- Footer -->
            <div class="grid gap-1 has-2-columns box-footer p pl-2 pr-1 align-center has-text-cool-blue-text">
              <article class="has-text-weight-light">
                opened by
                <template v-if="job.user.id === job.technician.id">{{ Naming.Technician }} </template>
                <route v-else class="has-text-cool-blue-text-dark has-text-weight-normal" name="user-overview"
                  :params="{ user: job.user.id }">{{ job.user.full_name }}
                </route>
                <template v-if="job.scheduled_job"> via
                  <route
                    class="has-text-cool-blue-text-dark has-text-weight-normal tooltip has-tooltip-top has-tooltip-cool-blue-dark"
                    data-tooltip="Manage Schedule"
                    name="recurring-job-manager"
                    :params="{ recurringJob: job.scheduled_job.recurring_job_id }">
                    <icon
                      class="is-small has-text-info"
                      icon="sync xs"/>
                    {{ Naming.RecurringJob }}
                  </route>
                </template>
                <moment use-tooltip class="has-tooltip-cool-blue-dark ml" :time="job.created_at"/>
              </article>
              <div class="grid has-3-columns gap-1 is-mobile">
                <article>
                  <span class="tag is-small is-cool-blue mr">{{ job.zones_count }}</span>
                  <span class="is-size-7-mobile">{{ Naming.Zone | plural(job.zones_count) }}</span>
                </article>
                <article>
                  <span class="tag is-small is-cool-blue mr">{{ job.inspections_count }}</span>
                  <span class="is-size-7-mobile">{{ Naming.Inspections | plural(job.inspections_count) }}</span>
                </article>
                <article class="is-flex justify-end align-start">
                  <current-status class="has-text-right" :latest-status="job.latest_status" :due="job.end_date"/>
                </article>
              </div>
            </div>
          </div>
        </div>

        <pager jump-controls
          class="mt-2"
          button-class="is-cool-blue-darker is-rounded"
          details-class="is-rounded"
          :pageable="jobs"
          @nav="goToPage"
          :context="Naming.Jobs.toLowerCase()"/>
      </template>
    </template>

  </page>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { job as backend } from '@/api'
import JobFilters from './partials/Filters'
import download from 'downloadjs'

export default {

  components: {
    JobFilters,
  },

  data: () => ({
    loading: true,
    appliedFilters: {},
    pullingReport: false,
  }),

  created() {
    this.clearAllStores()
    this.loadJobs()
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('checklist', [
      'setChecklistsList',
    ]),
    ...mapMutations('client', [
      'setClientList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('assetType', [
      'setAssetTypeList',
    ]),
    goToPage(path) {
      this.$router.push(path)
      this.$scrollToTop()
    },

    loadJobs() {
      this.$store.dispatch('job/loadJobs', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      }).then(() => this.loading = false)
    },

    navigateToJob(job) {
      this.$router.push({
        name: 'job-handler',
        params: { job }
      })
    },

    async pullReport(type) {
      this.pullingReport = true

      await backend.downloadFilteredIndexReport(
        type,
        this.appliedFilters,
        response => this.jobs.meta.total > 1000
          ? this.$toast.success(`You will receive an email at <b>${this.user.email}</b> in a few minutes with your export.`)
          : this.downloadReport(type, response),
        error => this.$whoops()
      )

      this.pullingReport = false
    },

    downloadReport(type, response) {
      this.$toast.success(`Report retrieved. Please save the ${type.toUpperCase()}.`)
      download(
        response.data,
        `All Jobs as at ${moment().format('DD-MM-YYYY')}.${type}`,
        `application/${type}`
      )
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    },
    clearAllStores() {
      this.setUserList([])
      this.setChecklistsList([])
      this.setClientList([])
      this.setSiteList([])
      this.setAssetTypeList([])
    }
  },

  computed: {
    ...mapState('job', [
      'jobs'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('auth', [
      'user',
      'isAdmin'
    ])
  },

  watch: {
    $route: 'loadJobs'
  },

beforeRouteLeave(to, from, next) {
  this.clearAllStores()
  next()
}

}
</script>
